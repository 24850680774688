html,
body {
    height: 100%;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

a {
    cursor: pointer;
}

.wrap {
    min-height: 100%;
    height: auto;
    margin: 0 auto -60px;
    padding: 0 0 60px;
}

.wrap > .container {
    padding: 70px 15px 20px;
}

.footer {
    height: 60px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.jumbotron {
    text-align: center;
    background-color: transparent;
}

.jumbotron .btn {
    font-size: 21px;
    padding: 14px 24px;
}

.not-set {
    color: #c55;
    font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    padding-left: 5px;
}

a.asc:after {
    content: /*"\e113"*/ "\e151";
}

a.desc:after {
    content: /*"\e114"*/ "\e152";
}

.sort-numerical a.asc:after {
    content: "\e153";
}

.sort-numerical a.desc:after {
    content: "\e154";
}

.sort-ordinal a.asc:after {
    content: "\e155";
}

.sort-ordinal a.desc:after {
    content: "\e156";
}

.grid-view th {
    white-space: nowrap;
}

.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}

.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}

/* align the logout "link" (button in form) of the navbar */
.nav li > form > button.logout {
    padding: 15px;
    border: none;
}

@media (max-width: 767px) {
    .nav li > form > button.logout {
        display: block;
        text-align: left;
        width: 100%;
        padding: 10px 15px;
    }
}

.nav > li > form > button.logout:focus,
.nav > li > form > button.logout:hover {
    text-decoration: none;
}

.nav > li > form > button.logout:focus {
    outline: none;
}

.table-active-row {
    cursor: pointer;
}

.table-active-row:hover {
    background-color: #fcf8e3;
}

.table-active-row .btn {
    opacity: 0;
}

.table-active-row:hover .btn {
    opacity: 1;
}

.panel table {
    width: auto;
}

.panel table:last-child {
    margin-bottom: 0;
}

.panel table tr th {
    border-top: 0;
    min-width: 50px;
}

.form-group .form-control.with-buttons {
    max-width: 59px;
    border-radius: 4px;
    float: left;
}

.form-group .form-control.with-buttons ~ .btn-group {
    padding-left: 1.24rem;
    float: left;
}

.modal-footer .pull-left {
    width: 100%;
    text-align: left;
}

.link {
    cursor: pointer;
}

.department__checkbox-wrapper {
    background-image: url(../_/images/lg_empty.svg);
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center center;
    transition: opacity 0.3s;
    opacity: 0;
    position: relative;
    top: 0;
    margin-right: 0;
}

.department__checkbox-wrapper.checked {
    background-image: url(../_/images/lg_selected.svg);
    opacity: 1;
}

.department__checkbox-wrapper.partial {
    background-image: url(../_/images/lg_partial.svg);
    opacity: 1;
}

.department__checkbox-wrapper .department-check {
    opacity: 0;
    margin: 0;
    display: block;
    height: 20px;
    width: 20px;
}

.box-check {
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.box-text {
    display: inline-block;
    position: relative;
    cursor: default;
    vertical-align: bottom;
    line-height: 22px;
}

.ico {
    display: inline-block;
    background-repeat: no-repeat;
}

.ico:hover {
    box-shadow: none;
}

.name-icons {
    padding: 8px;
    padding-right: 0;
    padding-left: 0;
    display: inline-block !important;
    width: 18px;
    float: right;
    margin-right: 0 !important;
}

.name-icons a:hover {
    box-shadow: none;
}

.name-toggle .ico {
    vertical-align: middle;
    margin-right: 5px;
    width: 34px;
    height: 34px;
}

.ico-edit-name {
    background-image: url(../_/images/play_icons/edit-name.svg);
}

.ico-edit-name:hover {
    background-image: url(../_/images/play_icons/edit-name-h.svg);
}

.ico-preview-name {
    background-image: url(../_/images/play_icons/preview.svg);
}

.ico-preview-name:hover {
    background-image: url(../_/images/play_icons/preview-h.svg);
}

.ico-save-name {
    background-image: url(../_/images/play_icons/save-name.svg);
}

.ico-save-name:hover {
    background-image: url(../_/images/play_icons/save-name-h.svg);
}

.input-group-addon-between {
    border-left: 0;
    border-right: 0;
}

.has-error .mce-tinymce {
    border-color: #a94442;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    min-height: 27px;
}

table tr td p {
    margin-bottom: 5px;
}

table tr td p:last-child {
    margin-bottom: 0;
}

.loader {
    background: url(../_/images/loading.svg) no-repeat center center;
    -webkit-background-size: 45px 45px;
    -moz-background-size: 45px 45px;
    background-size: 45px 45px;
    min-height: 50px;
}

.sub-checkboxes {
    margin: 5px 0 5px 0;
}

.form-group .sub-checkboxes p {
    margin: 5px 0 0 0;
}

.form-group checkboxes p {
    margin: 5px 0 0 0;
}

checkboxes p a {
    margin-right: 5px;
}

.package .panel-body {
    position: relative;
}

.package .panel-body .close-package {
    position: absolute;
    top: 5px;
    right: 5px;
    line-height: 10px;
    width: 14px;
    text-align: center;
    cursor: pointer;
}

.alert-grey {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #dddddd;
}

.form-control[readonly] {
    cursor: pointer;
    background-color: #fff;
}

.form-control[readonly][disabled], .form-control[readonly][disabled] + span {
    cursor: not-allowed;
}

.modal-body > div:last-child {
    margin-bottom: 0;
}

.panel-heading .license-package {
    height: auto;
}

.panel-heading .license-package .progress {
    height: 10px;
    margin-bottom: 0;
}

.panel-heading .license-package .progress-info {
    margin-bottom: 2px;
}

.panel-heading .license-package .progress {
    margin-bottom: 3px;
}

.panel-heading .license-package:last-child .progress {
    margin-top: 0;
}

.panel-default>.panel-heading {
    background-color: #ffffff;
}

.navbar-default {
    background-color: #ffffff;
}

.navbar-default .navbar-brand {
    color: initial;
}

.navbar-default .navbar-nav>li>a {
    color: initial;
}

.dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus {
    color: #ffffff;
    background-color: #337ab7;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav>.open>a:focus {
    color: #337ab7;
    background-color: #ffffff;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav>li>a:focus {
    color: #337ab7;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
    color: #337ab7;
}

tr.disabled td {
    opacity: 0.5;
}

tr.disabled td.actions {
    opacity: 1;
}

.btn-default.active.alert-danger, .btn-default.alert-danger:hover {
    color: #a94442;
    background-color: #f2dede;
    border-color: #a94442;
}

.btn-default.active.alert-warning, .btn-default.alert-warning:hover {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #8a6d3b;
}

.btn-default.active.alert-info, .btn-default.alert-info:hover {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #31708f;
}

.btn-default.active.alert-success, .btn-default.alert-success:hover {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #3c763d;
}

.footer {
    background-color: #ffffff;
    margin-top: 50px;
}

.fa-arrow-down:before {
    content: "↓";
}

.fa-arrow-up:before {
    content: "↑";
}

.modal-body .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
}

.ui-select-bootstrap .ui-select-match-text {
    text-overflow: ellipsis;
    overflow: hidden;
}

.modal-checkbox-selector {
    margin-top: -7px;
}

.has-error .no-error {
    color: #555;
    background-color: #fff;
    border-color: #ccc;
}

.has-error .no-error:focus {
    color: #555;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
    border-color: #66afe9;
}

.has-error .no-error.input-group-addon, .has-error .no-error.input-group-addon:focus {
    background-color: #eee;
}
