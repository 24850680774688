h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.form-group textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 150px;
}
.form-group textarea.copy {
  cursor: pointer;
}
.form-group textarea.small {
  height: auto;
  min-height: 50px;
}
.form-group p {
  margin: 10px 0 0 0;
}
.drop-box {
  position: relative;
  background: #F8F8F8;
  border: 5px dashed #DDD;
  width: 100%;
  height: 200px;
  text-align: center;
  margin: 0;
  font-size: 2em;
  color: #AAA;
  line-height: 4em;
}
.drop-box .btn {
  position: absolute;
  left: calc(50% - 70px);
  display: inline;
  bottom: 40px;
  margin: 0 auto;
}
ng-form[name=attachmentForm] .progress {
  margin-bottom: 0;
}
.inline-items-group {
  display: block;
}
.inline-items-group .inline-item {
  display: inline-block;
  margin-right: 15px;
  vertical-align: top;
  height: 34px;
}
.inline-items-group .inline-item:last-child {
  margin-right: 0;
}
.inline-items-group .inline-item.inline-item-input {
  min-width: 300px;
  max-width: 300px;
}
.inline-items-group .inline-item input[type=text] {
  height: 35px;
}
.links > * {
  padding-left: 5px;
}
.links > *:first-child {
  padding-left: 0px;
}
attachment-instances .form-group:last-child {
  margin-bottom: 0;
}
.process-container {
  margin-left: 15px;
  line-height: 28px;
  display: inline-block;
  text-align: left;
  width: 355px;
  float: right;
  min-height: 33px;
}
.process-container.decrypt {
  width: 325px;
}
.process-container.small {
  width: 315px;
}
.process-container.text {
  width: 100%;
  margin-top: 20px;
  margin-left: 0;
}
.process-container > div {
  display: inline-block;
}
.process-container .process-text {
  float: right;
  text-align: left;
  width: calc(100% - 20px);
}
.error-container {
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  height: auto;
  margin: 85px 0;
}
.error-container .logo-container {
  display: block;
  height: 90px;
  width: 100%;
}
.error-container .logo-container .logo {
  display: block;
  float: left;
  background-size: cover;
  width: 75px;
  height: 90px;
  background-image: url(../_/images/af-logo-small.svg);
  background-repeat: no-repeat;
  margin-left: -35px;
}
.error-container .logo-container .separator {
  display: none;
}
.error-container .logo-container .slogan {
  display: none;
}
.error-container .message-container {
  width: 100%;
  display: block;
  margin-top: 20px;
}
.error-container .message-container h1 {
  font-size: 31px;
}
.error-container .message-container .message {
  margin-top: 45px;
  width: 100%;
  font-size: 14px;
  display: block;
}
.languages {
  text-align: center;
}
.languages > a {
  padding-left: 20px;
  text-decoration: none;
  box-shadow: none;
}
.languages .lang-flag {
  width: 14px;
  height: 9px;
  display: inline-block;
  margin-right: 5px;
}
.languages .lang-flag.ru {
  background-image: url(../_/images/ru.png);
}
.languages .lang-flag.en {
  background-image: url(../_/images/en.png);
}
.languages .fa {
  margin-left: 3px;
}
.languages:hover {
  padding-top: 10px;
  margin-top: -10px;
}
.languages:hover .languages-list {
  display: block;
}
.languages .languages-list {
  position: relative !important;
  display: none;
  margin-top: -90px;
}
.languages .languages-list a {
  display: block;
  width: 100%;
  text-decoration: none;
  box-shadow: none;
}
.languages .languages-list a:hover {
  background-color: #428bca;
  color: #ffffff;
}
